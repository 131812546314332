import React, { Component } from 'react'

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="header">
                <ul>
                    <li>
                        {
                            this.props.language === 'French' 
                            ? <><span className="benefits">Votre <strong>Avantages</strong></span></>
                            : <><span className="benefits">Your <strong>Benefits</strong></span></>
                        }
                    </li>
                    <li>
                        {
                            this.props.language === 'French' 
                            ? <><span className="learning-development">Votre <strong>Apprentissage et D&eacute;veloppement</strong></span></>
                            : <><span className="learning-development">Your <strong>Learning & Development</strong></span></>
                        }
                    </li>
                    <li>
                        {
                            this.props.language === 'French' 
                            ? <><span className="work-environment">Votre <strong>Environnement de Travail</strong></span></>
                            : <><span className="work-environment">Your <strong>Work Environment</strong></span></>
                        }
                    </li>
                    <li>
                        {
                            this.props.language === 'French' 
                            ? <><span className="compensation">Votre <strong>R&eacute;mun&eacute;ration</strong></span></>
                            : <><span className="compensation">Your <strong>Compensation</strong></span></>
                        }
                    </li>
                </ul>
            </div>
        );
    }
}

export default Header
