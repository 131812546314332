import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { ReactComponent as Remove } from '../icons/remove.svg'

class Favourites extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="favourites-container" id="favourites" ref={this.props.favRef}>
                {
                    this.props.language === 'French'
                    ? <h2>Favoris</h2>
                    : <h2>Favourites</h2>
                }
                {this.props.favourites.length <= 0 &&
                    <>
                    {
                        this.props.language === 'French'
                        ? <p className="favourite-disclaimer">Vous pouvez ajouter des favoris en cliquant sur l'ic&ocirc;ne &eacute;toile sur un élément.</p>
                        : <p className="favourite-disclaimer">You can add favorites by clicking on the star icon on an element.</p>
                    }
                    </> 
                }
                <ul className="favourites-list">
                    {
                        this.props.favourites.map(fave => {
                            return (
                                <li className={fave.Category} key={fave.Name}>
                                    <div className="favourite-content">
                                        <Remove className="remove" onClick={() => this.props.removeFavourite(fave)}/>
                                        {fave.Image
                                            ? <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute('style', 'width: 100px; height: 100px')
                                                    svg.setAttribute('stroke', 'white')
                                                    svg.setAttribute('fill', 'white')
                                                }}
                                                src={fave.Image[0].url} />
                                            : null
                                        }
                                        <div className="favourite-content__text">
                                            <h3>{
                                                this.props.language === 'French'
                                                ? fave['Name (French)']
                                                : fave.Name
                                            }</h3> 
                                            <p>     
                                                {
                                                    this.props.language === 'French'
                                                        ? fave['Description (French)']
                                                        : fave.Description
                                                }
                                            </p>
                                            <a href={
                                                this.props.language === 'French' && typeof fave['Link (French)'] !== 'undefined'
                                                    ? fave['Link (French)']
                                                    : fave.Link
                                                } 
                                                className={fave.Category} target="_blank" rel="noopener noreferrer">
                                                    {
                                                        this.props.language === 'French'
                                                            ? 'En savoir plus'
                                                            : 'Learn more'
                                                    }
                                                </a>
                                        </div>
                                        
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            {this.props.favourites.length > 0 &&  
                <button className="clear-favourites" onClick={this.props.clearFavourites}>
                    {
                        this.props.language === 'French'
                        ? 'Effacer tous les favoris'
                        : 'Clear all favourites'
                    }
                    <Remove />
                </button>
            }
            </div>
        );
    }
}

export default Favourites
