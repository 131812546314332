import smoothscroll from 'smoothscroll-polyfill'; 
import React, { Component } from 'react'

import Header from '../components/Header'
import ElementCard from '../components/ElementCard'
import ElementDetails from '../components/ElementDetails'
import CategoryDetails from '../components/CategoryDetails'
import Favourites from '../components/Favourites'
// import ElementData from '../data/elements-table.json';
import { ReactComponent as Star } from '../icons/star.svg';
import { ReactComponent as Logo } from '../icons/Logo.svg';
import { ReactComponent as LogoFrench } from '../icons/Logo-French.svg';

import Airtable from 'airtable'
const base = new Airtable({ apiKey: 'pat4hZIaxSmWvIYRP.552e1b99cb37ff2d0ffb58df6e1aa963c3482c5f63fa4ecbecf942bd7c26f610' }).base('app6GX5z8EpC8ERK4');


class TableContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            elementCards: [],
            title: '',
            details: '',
            category: '',
            favourites: [],
            country: 'Canada',
            language: 'English',
            selectedCard: null,
        }
        this.cardRefs = {}
        this.favRef = React.createRef();
    }

    async componentDidMount() {
        smoothscroll.polyfill();
        this.displayTable(this.state.country)
    }

    displayTable = country => {
        try {

            const favourites = localStorage.getItem('favourites') !== null ? JSON.parse(localStorage.getItem('favourites')) : []

            base('Canada').select({ view: 'Grid view', sort: [{ field: "Table Order", direction: "asc" }]})
                .eachPage(
                    (records, fetchNextPage) => {
                        const elementCards = records.map(card => {
                            card.fields.isFavourite = false
                            if (favourites.some(item => item.Name === card.fields.Name)) {
                                card.fields.isFavourite = true
                            }
                            return card
                        })
                        console.log('cards', elementCards)
                        this.setState({
                            elementCards, favourites
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }
    }

    updateDetails = card => {
        console.log('update', card)
        console.log(this.state.language)
        if (card !== null) {
            this.setState({ 
                selectedCard: card,
                title: this.state.language === 'French' && card['Name (French)'] !== null ? card['Name (French)'] : card.Name,
                details: this.state.language === 'French' && card['Description (French)'] !== null ? card['Description (French)'] : card.Description,
                category: card.Category,
                image: typeof card.Image !== 'undefined' ? card.Image[0] : null
            })
        }
    }

    toggleFavourite = card => {
        card.isFavourite = !card.isFavourite
        let newFavourites = []
        if (this.state.favourites.some(item => item.Name === card.Name)) {
            newFavourites = this.state.favourites.filter(function (el) { return el.Name !== card.Name; });
        } else {
            newFavourites = [ ...this.state.favourites, card ]
        }

        this.setState({ favourites: newFavourites }, () => {
            localStorage.setItem('favourites', JSON.stringify(this.state.favourites))
        })
    }

    removeFavourite = card => {
        card.isFavourite = false
        const newFavourites = this.state.favourites.filter(function (el) { return el.Name !== card.Name; });

        const newElementCards = this.state.elementCards.map(el => {
            if (el.fields.Name === card.Name) {
                console.log('yes', card.Name)
                el.fields.isFavourite = false
            }
            return el
        })

        this.setState({ favourites: newFavourites }, () => {
            localStorage.setItem('favourites', JSON.stringify(this.state.favourites))
        })
    }

    clearFavourites = () => {
        this.state.elementCards.forEach(card => card.fields.isFavourite = false)
        this.setState({ favourites: [] })
        localStorage.clear()
    }

    setLanguage = language => {
        this.setState({ language }, () => this.updateDetails(this.state.selectedCard))
    }

    render() {
        return (
            <div className="container">
                <div className="country-toggle">
                    <span onClick={() => this.setLanguage('English')}>English</span>
                    <span onClick={() => this.setLanguage('French')}>French</span>
                </div>
                {this.state.favourites.length > 0 && 
                    <button className="see-favourites" onClick={() => this.favRef.current.scrollIntoView()}>
                        {
                            this.state.language === 'French' 
                            ? 'Voir les favoris' 
                            : 'See Favourites'
                        }
                        <Star />
                    </button>
                }
                <Header setLanguage={this.setLanguage} language={this.state.language} />
                <div className={`element-table ${this.state.country.toLowerCase()}`}>
                    {
                        this.state.elementCards.map((card, index) => {
                            if (index === 2) {
                                return (
                                    <>
                                        <div className="table-header">
                                            {
                                                this.state.language === 'French'
                                                ? <LogoFrench />
                                                : <Logo />
                                            }
                                        </div>
                                        <ElementCard card={card.fields} key={card.fields.Name} favourites={this.state.favourites} updateDetails={this.updateDetails} toggleFavourite={this.toggleFavourite} index={index} language={this.state.language} />
                                    </>
                                )
                            } else {
                                return <ElementCard card={card.fields} key={card.fields.Name} favourites={this.state.favourites} updateDetails={this.updateDetails} toggleFavourite={this.toggleFavourite} index={index} language={this.state.language} />
                            }
                        })
                    }
                    <div className="details">
                        <CategoryDetails category={this.state.category} language={this.state.language}/>
                        <ElementDetails title={this.state.title} details={this.state.details} category={this.state.category} image={this.state.image} language={this.state.language} />
                    </div>
                    <Favourites favRef={this.favRef} favourites={this.state.favourites} removeFavourite={this.removeFavourite} clearFavourites={this.clearFavourites} language={this.state.language} />
                </div>
            </div>
        );
    }
}

export default TableContainer
