import React, { Component } from 'react'
import { render } from 'react-dom'
import './App.css'
import Denied from './Denied'
import TableContainer from './containers/TableContainer'

class UsTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allowed: false,
            checking: true,
            allowedIps: []
        }
    }

    async componentDidMount() {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            const API_URL = "https://eres-node-ipfilter.azurewebsites.net/"

            await fetch(API_URL, requestOptions)
                .then(response => {
                    console.log('response', response)
                    if (response.status === 200) {
                        console.log('yes')
                        this.setState({ allowed: true, checking: false })
                    } else {
                        console.log('no')
                        this.setState({ allowed: false, checking: false })
                    }
                })
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
        } catch (err) {
            console.log('err', err)
            this.setState({ allowed: false, checking: false })
        }
    }

    render() {
        return (
            <div className="App">
                {
                    this.state.checking
                        ? <h1 style={{ paddingTop: 200, fontSize: 35 }}>Creating chemistry...</h1>
                        : this.state.allowed 
                            ? <TableContainer />
                            : <Denied />
                }
            </div>
        );
    }
}

export default UsTable
