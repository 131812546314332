import React, { Component } from 'react'
import { ReactComponent as Benefits } from '../icons/icon-benefits.svg';
import { ReactComponent as LearningDevelopment } from '../icons/icon-learning-development.svg';
import { ReactComponent as WorkEnvironment } from '../icons/icon-work-environment.svg';
import { ReactComponent as Compensation } from '../icons/icon-compensation.svg';

class CategoryDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount() {
        console.log('details', this.props.language)
    }

    renderDetails = (category) => {
        switch (category) {
            case 'benefits':
                return (
                    <>
                        <Benefits />
                        <div>
                            {
                                this.props.language === 'French'
                                ? <><span>Avantages sociaux</span><p>Des avantages comp&eacute;titifs, flexibles et adapt&eacute;s à vos besoins, quelle que soit l'&eacute;tape o&ugrave; vous en &ecirc;tes dans la vie.</p></>
                                : <><span>Benefits</span><p>Benefits that are competitive, flexible and tailored to your needs, wherever you are in life.</p></>
                            }                            
                        </div>
                    </>
                )
                break;
            case 'learning-development':
                return (
                    <>
                        <LearningDevelopment />
                        <div>
                        {
                            this.props.language === 'French'
                            ? <><div><span>Apprentissage et D&eacute;veloppement</span><p>Apprenez de nouvelles comp&eacute;tences et d&eacute;veloppez votre carri&egrave;re tout en accomplissant un travail qui fait une diff&eacute;rence.</p></div></>
                            : <><div><span>Learning & Development</span><p>Learn new skills and develop your career while doing meaningful work.</p></div></>
                        }
                        </div>
                    </>
                )
                break;
            case 'work-environment':
                return (
                    <>
                        <WorkEnvironment />
                        <div>
                            {
                                this.props.language === 'French'
                                ? <><span>Environnement de Travail</span><p>Un environnement de travail collaboratif, inclusif et s&eacute;curitaire o&ugrave; vous pouvez &ecirc;tre vous-m&ecirc;me – et r&eacute;ussir.</p></>
                                : <><span>Work Environment</span><p>A safe, inclusive and collaborative work environment where you can be you – and succeed.</p></>
                            }
                        </div>
                    </>
                )
                break;
            case 'compensation':
                return (
                    <>
                        <Compensation />
                        <div>
                            {
                                this.props.language === 'French'
                                ? <><span>R&eacute;mun&eacute;ration</span><p>Une r&eacute;mun&eacute;ration concurrentielle et fond&eacute;e sur le rendement, ainsi que des r&eacute;compenses dont vous pouvez vous r&eacute;jouir.</p></>
                                : <><span>Compensation</span><p>Competitive and performance-based compensation and rewards you can feel good about.</p></>
                            }
                        </div>
                    </>
                )
                break;
        
            default:
                break;
        }
    }

    render() {
        return (
            <div className="category-details">
                {this.renderDetails(this.props.category)}
            </div>
        );
    }
}

export default CategoryDetails
