import smoothscroll from 'smoothscroll-polyfill'; 
import React, { Component } from 'react'

import Header from '../components/Header'
import ElementCard from '../components/ElementCard'
import ElementDetails from '../components/ElementDetails'
import CategoryDetails from '../components/CategoryDetails'
import Favourites from '../components/Favourites'
// import ElementData from '../data/elements-table.json';
import { ReactComponent as Star } from '../icons/star.svg';
import { ReactComponent as Logo } from '../icons/Logo.svg';

import Airtable from 'airtable'
const base = new Airtable({ apiKey: 'keyZwQwIDtkQ3t4UV' }).base('appj3gyMTsVAqGPTH');


class TableContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            elementCards: [],
            title: '',
            details: '',
            category: '',
            favourites: [],
            country: 'United States',
        }
        this.cardRefs = {}
        this.favRef = React.createRef();
    }

    async componentDidMount() {
        smoothscroll.polyfill();
        this.displayTable(this.state.country)
    }

    displayTable = country => {
        try {

            const favourites = localStorage.getItem('favourites') !== null ? JSON.parse(localStorage.getItem('favourites')) : []

            base(country).select({ view: 'Grid view' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const elementCards = records.map(card => {
                            card.fields.isFavourite = false
                            if (favourites.some(item => item.Name === card.fields.Name)) {
                                card.fields.isFavourite = true
                            }
                            return card
                        })
                        this.setState({
                            elementCards, favourites
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }
    }

    updateDetails = card => {
        this.setState({ 
            title: card.Name,
            details: card.Description,
            category: card.Category,
            image: typeof card.Image !== 'undefined' ? card.Image[0] : null
        })
    }

    toggleFavourite = card => {
        card.isFavourite = !card.isFavourite
        let newFavourites = []
        if (this.state.favourites.some(item => item.Name === card.Name)) {
            newFavourites = this.state.favourites.filter(function (el) { return el.Name !== card.Name; });
        } else {
            newFavourites = [ ...this.state.favourites, card ]
        }

        this.setState({ favourites: newFavourites }, () => {
            localStorage.setItem('favourites', JSON.stringify(this.state.favourites))
        })
    }

    removeFavourite = card => {
        card.isFavourite = false
        const newFavourites = this.state.favourites.filter(function (el) { return el.Name !== card.Name; });

        const newElementCards = this.state.elementCards.map(el => {
            if (el.fields.Name === card.Name) {
                console.log('yes', card.Name)
                el.fields.isFavourite = false
            }
            return el
        })

        this.setState({ favourites: newFavourites }, () => {
            localStorage.setItem('favourites', JSON.stringify(this.state.favourites))
        })
    }

    clearFavourites = () => {
        this.state.elementCards.forEach(card => card.fields.isFavourite = false)
        this.setState({ favourites: [] })
        localStorage.clear()
    }

    setCountry = country => {
        this.displayTable(country)
        this.setState({ country })
    }

    render() {
        return (
            <div className="container">
                {this.state.favourites.length > 0 && 
                    <button className="see-favourites" onClick={() => this.favRef.current.scrollIntoView()}>
                        See Favorites <Star />
                    </button>
                }
                <Header setCountry={this.setCountry} />
                <div className={`element-table ${this.state.country.toLowerCase()}`}>
                    {
                        this.state.elementCards.map((card, index) => {
                            if (index === 2) {
                                return (
                                    <>
                                        <div className="table-header">
                                            <Logo />
                                        </div>
                                        <ElementCard card={card.fields} key={card.fields.Name} favourites={this.state.favourites} updateDetails={this.updateDetails} toggleFavourite={this.toggleFavourite} index={index} />
                                    </>
                                )
                            } else {
                                return <ElementCard card={card.fields} key={card.fields.Name} favourites={this.state.favourites} updateDetails={this.updateDetails} toggleFavourite={this.toggleFavourite} index={index} />
                            }
                        })
                    }
                    <div className="details">
                        <CategoryDetails category={this.state.category} />
                        <ElementDetails title={this.state.title} details={this.state.details} category={this.state.category} image={this.state.image}/>
                    </div>
                    <Favourites favRef={this.favRef} favourites={this.state.favourites} removeFavourite={this.removeFavourite} clearFavourites={this.clearFavourites} />
                </div>
            </div>
        );
    }
}

export default TableContainer
